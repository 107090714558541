<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Category List') }}</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['publication-categories/create'])"
                         :to="'/publication-categories/create'"
                         class="btn btn-info btn-wd">
              {{ $store.getters['languageMixin/getStringFromLanguage']('Add New') }}
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'publication-categories/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Active')">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['publication-categories/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['publication-categories/update'])"
                             v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Edit')"
                             class="btn-warning btn-simple btn-link"
                             :to="'/publication-categories/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['publication-categories/delete'])"
                   v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Delete')"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this category?')"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>


    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {DeleteModal as DeleteModal, NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Name'), value: 'cms_name', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('URL'), value: 'url', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },


      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
    }

  },
  mounted() {
    this.$refs.table.createDragAndDropSorting();
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("publication-categories/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Category updated successfully"),
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
        'id': this.toDeleteId,
      }
      this.axios.delete("publication-categories/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Category deleted successfully"),
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },

    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.group_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },

    async reorder(data) {
      try {
        let body = {};
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          data: orderData,
        }

        await this.axios.post("publication-categories/re-order", body);

        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("order updated successfully"),
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },

  },

}
</script>
